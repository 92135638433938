import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Page = ({ data }) => {
  const page = data.markdownRemark;
  return (
    <Layout>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description}
        image={page.frontmatter.image}
        node={page}
      />
      <article className="mx-auto prose lg:prose-xl dark:prose-invert prose-a:text-indigo-600 dark:prose-a:text-indigo-400">
        <header>
          <h1>{page.frontmatter.title}</h1>
        </header>
        <section>
          <div dangerouslySetInnerHTML={{ __html: page.html }} />
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        image
      }
    }
  }
`;

export default Page;
